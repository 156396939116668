/* Scroll bar */
body::-webkit-scrollbar-track,
.ant-table-body::-webkit-scrollbar-track
{
	/* -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3)!important; */
	border-radius: 0px!important;
	background-color: transparent!important;
}

body::-webkit-scrollbar,
.ant-table-body::-webkit-scrollbar
{
	width: 10px!important;
	background-color: transparent!important;
}

body::-webkit-scrollbar-thumb,
.ant-table-body::-webkit-scrollbar-thumb
{
	border-radius: 10px!important;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3)!important;
	background-color: rgba(28,39,51,.85)!important;
}

body::-webkit-scrollbar, 
.ant-table-body::-webkit-scrollbar{
    width: 10px!important;
	background-color: transparent!important;
}
.ant-table-body{
    overflow-y: auto!important;
}
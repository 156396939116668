@import "./styles/theme.css";
@import "./styles/scroll-bar.css";
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
/* .image-container {
  border-radius: 8px !important;
  width: 250px !important;
  height: auto !important;
  overflow: hidden;
  padding: 0px !important;
  border: 2px solid #ccc;
  box-shadow: 1px 2px 2px 4px rgba(246, 246, 246, 0.568);
  margin: 10px !important;
} */
/* .image-bg {
  width: 100%;
  height: 200px !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: center center !important;
} */
.flat-button-file {
  border: none;
  background-color: #cccccc;
  padding: 7px 13px;
  font-size: 16px;
  cursor: pointer;
  display: inline-block;
  width: calc(100% - 30px);
  text-align: center;
  margin: 0px;

  font-weight: 500;
  line-height: 1.75;
  border-radius: 4px;

  color: #fff;
  background-color: #3f51b5;
}

.paddingItems {
  padding-top: 50px;
}

.padding-10px {
  padding-top: 10px !important;
}

.video-react-video {
  width: 550px;
  padding-top: 10px;
  margin-top: 0px !important;
}
